import { Modal, Button, message } from "antd";
import { useState } from "react";
import { completeInstallation, sendInstallationCodeToClient, acceptForInstallation, forceCompleteInstallation } from "../utils/fetch";
import PinInput from "react-pin-input";
import config from "../../../config.json";

/**
 * Компонент кнопки для принятия заказа на монтаж.
 */
export const AcceptButton = ({ lead, fetch }) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);

  const content = (
    <>
      <p>Вы точно хотите принять заказ на монтаж?</p>
    </>
  );

  const handleAccept = () => {
    acceptForInstallation(lead._id, fetch)
      .then(() => {
        message.success("Заказ успешно принят на монтаж.", 3);
        setShowAcceptModal(false);
      })
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err.response.data.message;
        }
        message.error(msg);
      });
  };

  return (
    <>
      <Button
        style={{
          padding: "5px 10px",
          color: "green",
          marginRight: 10,
        }}
        onClick={() => {
          setShowAcceptModal(true);
        }}
      >
        Принять
      </Button>
      <InstallationModal 
        visible={showAcceptModal}
        onSubmit={handleAccept}
        onCancel={() => setShowAcceptModal(false)}
        okText="Принять"
        content={content}
      />
    </>
  );
}

/**
 * Компонент кнопки для завершения монтажа, включая принудительное завершение.
 */
export const CompleteButton = ({ lead, fetch }) => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');

  /**
   * Обработчик для отправки кода монтажа клиенту.
   * @param {string} phone - Номер телефона клиента.
   * @param {string} id - Идентификатор заявки.
   */
  const onSendCode = async (phone, id) => {
    setIsLoading(true);
    sendInstallationCodeToClient(id, phone, 
      msg => {
        if (msg) {
          message.success(msg, 3);
        }
        setShowCompleteModal(true);
      },
    )
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message;
      }

      message.error(msg);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  /**
   * Обработчик для отправки PIN-кода для завершения монтажа.
   */
  const onSubmit = () => {
    setIsLoading(true);
    completeInstallation(pin, lead,
      msg => {
        if (msg) {
          message.success(msg, 5);
        }
        setShowCompleteModal(false);
      },
      () => {
        message.error("Неверный код", 3);
      },
      () => {
        fetch();
        setIsLoading(false);
      }
    )
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err?.response?.data?.message;
      }
      message.error(msg);
      setIsLoading(false);
    });
  };

  /**
   * Обработчик для принудительного завершения монтажа.
   */
  const handleForceComplete = () => {
    setIsLoading(true);
    forceCompleteInstallation(lead._id)
      .then(() => {
        message.success("Монтаж принудительно завершен.", 3);
        setShowForceCompleteModal(false);
        fetch();
      })
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err?.response?.data?.message;
        }
        message.error(msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const completeDeliveryContent = (
    <>
      <p>Чтобы завершить монтаж, введите код, который клиент получил на WhatsApp</p>
      <div className="my-3">
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, _index) => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ borderColor: "#dee2e6" }}
          inputFocusStyle={{ borderColor: "#1890ff" }}
          autoSelect={true}
          regexCriteria={/^[0-9]*$/} // Принимает только числа
        />
      </div>
    </>
  );

  const forceCompleteContent = (
    <>
      <p>Вы уверены, что хотите принудительно завершить монтаж?</p>
    </>
  );

  return (
    <>
      {/* Кнопка завершения монтажа */}
      <Button
        style={{
          padding: "5px 10px",
          color: "green",
          marginRight: 10,
        }}
        loading={isLoading}
        onClick={() => {
          onSendCode(lead.client[0].phone, lead._id);
        }}
      >
        Завершить монтаж
      </Button>

      {/* Кнопка принудительного завершения монтажа */}
      <Button
        style={{
          padding: "5px 10px",
          color: "red",
          marginRight: 10,
        }}
        loading={isLoading}
        onClick={() => {
          setShowForceCompleteModal(true);
        }}
      >
        Принудительно завершить
      </Button>

      {/* Модальное окно для ввода PIN-кода */}
      <InstallationModal
        visible={showCompleteModal}
        onSubmit={onSubmit}
        onCancel={() => setShowCompleteModal(false)}
        okText="Отправить"
        content={completeDeliveryContent}
        loading={isLoading}
      />

      {/* Модальное окно для подтверждения принудительного завершения */}
      <InstallationModal
        visible={showForceCompleteModal}
        onSubmit={handleForceComplete}
        onCancel={() => setShowForceCompleteModal(false)}
        okText="Да, завершить"
        content={forceCompleteContent}
        loading={isLoading}
      />
    </>
  );
}

/**
 * Переиспользуемый компонент модального окна монтажа.
 */
const InstallationModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
  loading,
}) => {
  return (
    <Modal
      title="Внимание!"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okText={okText}
      cancelText="Закрыть"
      okButtonProps={{
        style: {
          backgroundColor: okText === "Да, завершить" ? "red" : undefined,
          borderColor: okText === "Да, завершить" ? "red" : undefined,
        },
        loading: loading, // Показывает состояние загрузки, если loading=true
      }}
    >
      {content}
    </Modal>
  );
};

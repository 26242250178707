// kepler_front_new\src\pages\DeliveryStatus\components\DeliveryActionModal.jsx

import { Modal, Button, message } from "antd";
import { useState } from "react";
import { acceptDocumentsForDelivery, sendDeliveryCodeToClient, completeDelivery, forceCompleteDelivery } from "../utils/fetch";
import PinInput from "react-pin-input";

/**
 * Компонент для принятия документов для доставки.
 */
export const AcceptDocumentsForDeliveryButton = ({ lead, fetch }) => {
  const [showAcceptAccessoriesModal, setShowAcceptAccessoriesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const content = (
    <>
      <p>
        Убедитесь, что переданный заказ соответствует полученному вами Акту приема-передачи. С текущего момента ответственность за заказ лежит на вас.
      </p>
    </>
  );

  const handleAccept = () => {
    setIsLoading(true);
    acceptDocumentsForDelivery(lead._id, fetch)
      .then(() => {
        message.success("Документы успешно приняты для доставки.", 3);
        setShowAcceptAccessoriesModal(false);
      })
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err.response.data.message;
        }
        message.error(msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        style={{
          padding: "5px 10px",
          color: "green",
          marginRight: 10,
        }}
        onClick={() => {
          setShowAcceptAccessoriesModal(true);
        }}
      >
        Принять
      </Button>
      <DeliveryModal
        visible={showAcceptAccessoriesModal}
        onSubmit={handleAccept}
        onCancel={() => setShowAcceptAccessoriesModal(false)}
        okText="Принять"
        content={content}
        loading={isLoading}
      />
    </>
  );
};

/**
 * Компонент для завершения доставки, включая принудительное завершение.
 */
export const CompleteDeliveryButton = ({ lead, fetch }) => {
  const [showCompleteDeliveryModal, setShowCompleteDeliveryModal] = useState(false);
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');

  /**
   * Обработчик для отправки кода доставки клиенту.
   * @param {string} phone - Номер телефона клиента.
   * @param {string} id - Идентификатор заявки.
   */
  const onSendCode = async (phone, id) => {
    setIsLoading(true);
    sendDeliveryCodeToClient(id, phone,
      (msg) => {
        if (msg) {
          message.success(msg, 3);
        }
        setShowCompleteDeliveryModal(true);
      },
    )
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err.response.data.message;
        }
        message.error(msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Обработчик для отправки PIN-кода для завершения доставки.
   */
  const onSubmit = () => {
    setIsLoading(true);
    completeDelivery(pin, lead,
      (msg) => {
        if (msg) {
          message.success(msg, 5);
        }
        setShowCompleteDeliveryModal(false);
      },
      () => {
        message.error("Неверный код", 3);
      },
      () => {
        fetch();
        setIsLoading(false);
      }
    )
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err.response.data.message;
        }
        message.error(msg);
      });
  };

  /**
   * Обработчик для принудительного завершения доставки.
   */
  const handleForceComplete = () => {
    setIsLoading(true);
    forceCompleteDelivery(lead._id)
      .then(() => {
        message.success("Доставка принудительно завершена.", 3);
        setShowForceCompleteModal(false);
        fetch();
      })
      .catch((err) => {
        let msg = "Неизвестная ошибка";
        if (err?.response?.data?.message) {
          msg = err.response.data.message;
        }
        message.error(msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const completeDeliveryContent = (
    <>
      <p>
        Чтобы завершить доставку, введите код, который клиент получил на WhatsApp
      </p>
      <div className="my-3">
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, _index) => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ borderColor: "#dee2e6" }}
          inputFocusStyle={{ borderColor: "#1890ff" }}
          autoSelect={true}
          regexCriteria={/^[0-9]*$/} // Принимает только числа
        />
      </div>
    </>
  );

  const forceCompleteContent = (
    <>
      <p>Вы уверены, что хотите принудительно завершить доставку?</p>
    </>
  );

  return (
    <>
      {/* Кнопка завершения доставки */}
      <Button
        style={{
          padding: "5px 10px",
          color: "green",
          marginRight: 10,
        }}
        loading={isLoading}
        onClick={() => {
          onSendCode(lead.client[0].phone, lead._id);
        }}
      >
        Завершить доставку
      </Button>

      {/* Кнопка принудительного завершения доставки */}
      <Button
        style={{
          padding: "5px 10px",
          color: "red",
          marginRight: 10,
        }}
        loading={isLoading}
        onClick={() => {
          setShowForceCompleteModal(true);
        }}
      >
        Принудительно завершить
      </Button>

      {/* Модальное окно для ввода PIN-кода */}
      <DeliveryModal
        visible={showCompleteDeliveryModal}
        onSubmit={onSubmit}
        onCancel={() => setShowCompleteDeliveryModal(false)}
        okText="Отправить"
        content={completeDeliveryContent}
        loading={isLoading}
      />

      {/* Модальное окно для подтверждения принудительного завершения */}
      <DeliveryModal
        visible={showForceCompleteModal}
        onSubmit={handleForceComplete}
        onCancel={() => setShowForceCompleteModal(false)}
        okText="Да, завершить"
        content={forceCompleteContent}
        loading={isLoading}
      />
    </>
  );
};

/**
 * Переиспользуемый компонент модального окна доставки.
 */
const DeliveryModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
  loading,
}) => {
  return (
    <Modal
      title="Внимание!"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okText={okText}
      cancelText="Закрыть"
      okButtonProps={{
        style: {
          backgroundColor: okText === "Да, завершить" ? "red" : undefined,
          borderColor: okText === "Да, завершить" ? "red" : undefined,
        },
        loading: loading, // Показывает состояние загрузки, если loading=true
      }}
    >
      {content}
    </Modal>
  );
};

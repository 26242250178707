import { message } from "antd";
import config from "../../../config.json";
import { patchRequest, postRequest } from "../../../utils/requests";

/**
 * Функция для получения статусов заявок по состоянию и статусу.
 */
export const fetchStatuses = (
  state = "NEW",
  status = "",
  query = "",
  setSpecifications = () => {},
  setIsLoading = () => {}
) => {
  let url = `${config.service}/api/appl/state?search=${query}`;

  const body = {
    state,
  };
  if (status) {
    body.status = status;
  }

  const response = postRequest(url, body, {});

  response
    .then((data) => {
      const filteredApplications = data.data.application.filter(app => app.isHasMontage === true);
      setSpecifications(filteredApplications);
    })
    .catch((err) => {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

/**
 * Функция для принятия заявки на монтаж.
 */
export const acceptForInstallation = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "INSTALLING",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

/**
 * Функция для отправки кода установки клиенту.
 */
export const sendInstallationCodeToClient = (id, phone, onSuccess, onFinish) => {
  const url = `${config.service}/api/appl/verificationCode/${id}`;
  const body = {
    phone: phone,
    type: "INSTALLATION",
  };

  return postRequest(url, body) // Возвращаем промис
    .then(data => {
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      let msg = "Неизвестная ошибка";
      if (e?.response?.data?.message) {
        msg = e.response.data.message;
      }
      message.error(msg);
      throw e; // Пробрасываем ошибку для дальнейшей обработки
    })
    .finally(() => {
      if (onFinish) {
        onFinish();
      }
    });
};

/**
 * Функция для завершения установки с вводом PIN-кода.
 */
export const completeInstallation = (pin, lead, onSuccess, onError, onFinish) => {
  const url = `${config.service}/api/appl/verifyInstallationCode/${lead._id}`;
  const body = {
    id: lead._id,
    code: pin,
    phone: lead.client[0].phone,
  };

  postRequest(url, body)
    .then(data => {
      console.log('message', data.data);
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      console.log('error while completing installation', e);
      onError();
    })
    .finally(() => {
      if (onFinish) {
        onFinish();
      }
    });
};

/**
 * Функция для принудительного завершения монтажа.
 * @param {string} id - Идентификатор заявки.
 * @returns {Promise} - Возвращает промис с результатом запроса.
 */
export const forceCompleteInstallation = (id) => {
  const url = `${config.service}/api/appl/force-complete-install/${id}`;
  return postRequest(url, {})
    .then((data) => {
      if (data?.status === 200) {
        message.success("Монтаж принудительно завершен.", 3);
        return data?.data || '';
      }
      throw new Error(data?.data?.message || "Неизвестная ошибка");
    })
    .catch((error) => {
      let msg = "Неизвестная ошибка";
      if (error?.response?.data?.message) {
        msg = error.response.data.message;
      }
      message.error(msg);
      throw error;
    });
};

import { CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { getDeadlineColor } from "../../../utils/deadlineColor";
import { formatStringToDate } from "./format";
import { 
  AcceptAccessoriesButton, 
  AcceptButton, 
  CompleteAssemblyButton, 
  ConfirmPickupButton, 
  ForceCompleteAssemblyButton  // Добавлен импорт
} from "../components/AssemblyActionModal";

export const specificationProductsColumn = [
  {
    title: "№",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Наименование товара",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Размеры",
    dataIndex: "size",
    key: "size",
  },
  {
    title: "Открывание",
    dataIndex: "opening",
    key: "opening",
  },
  {
    title: "Алюминий",
    dataIndex: "aluminium",
    key: "aluminium",
  },
  {
    title: "Кол - во",
    dataIndex: "amount",
    key: "amount",
    width: 75,
  }
];

export const orderStatusColumnsNew = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 400,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 120,
  },
  {
    key: "amount",
    title: (
      <div
        style={{
          width: 56,
        }}
      >
        Кол-во
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 65,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "accessories",
    title: "Фурнитура",
    dataIndex: "accessories",
    width: 200,
  },
  {
    key: "actions",
    title: "Принять",
    dataIndex: "actions",
    width: 100,
  },
];

export const getOrderStatusDataSource = (leads, fetch) => {
  console.log("Все leads:", leads); // Отладочный вывод
  const readyForShipmentLeads = leads.filter(lead => lead.state === "ACTIVE" && lead.status === "READY_FOR_SHIPMENT" && !lead.isHasDelivery);
  console.log("Leads с state === 'ACTIVE' и status === 'READY_FOR_SHipment':", readyForShipmentLeads);

  const getAccessories = (lead) => {
    if (!lead.accessoriesAcceptedDate) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AcceptAccessoriesButton lead={lead} fetch={fetch}/>
        </div>
      )
    } else {
      return (
        <Tooltip title="Фурнитура получена">
          <div
            style={{
              padding: "10px 10px",
              color: "green",
              marginRight: 10,
              borderColor: "#d9d9d9",
              background: "#fff",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CheckOutlined />
          </div>
        </Tooltip>
      )
    }
  }

  const getActions = (lead) => {
    console.log("getActions called for lead:", lead._id); // Отладочный вывод

    if (lead.state === "ACTIVE" && lead.status === "PASSED_TO_ASSEMBLY") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AcceptButton lead={lead} fetch={fetch}/>
        </div>
      )
    }

    if (lead.state === "ACTIVE" && lead.status === "LOOP_MILLING") {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CompleteAssemblyButton lead={lead} fetch={fetch}/>
          {/* Удалено ForceCompleteAssemblyButton */}
        </div>
      )
    }

    if (lead.state === "ACTIVE" && lead.status === "READY_FOR_SHIPMENT" && !lead.isHasDelivery) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ConfirmPickupButton lead={lead} fetch={fetch}/>
          <ForceCompleteAssemblyButton lead={lead} fetch={fetch}/> {/* Добавлено */}
        </div>
      )
    }

    return null; // Если ни одно условие не выполнено
  }

  const dataSource = [];
  for (let lead of leads) {
    const client = lead.client[0];
    const user = lead?.user;

    let responsibleUser = "";
    if (user) {
      responsibleUser = `${user.name} ${user.surname}`;
    }

    let props = {};
    if (lead.state === "ACTIVE") {
      props = getDeadlineColor(lead?.assemblyDeadline, 1);
    }

    let aluminiumColor = "";
    if (Array.isArray(lead?.aluminium)) {
      aluminiumColor = lead?.aluminium.filter((v) => v !== "").join(", ");
    }

    dataSource.push({
      key: lead._id,
      data: lead,
      montage: lead.isHasMontage ? "Есть" : "Нет",
      responsibleUser,
      agreementNumber: lead?.lead?.agreementNumber,
      phoneNumber: client?.phone,
      address: lead?.lead?.address,
      fullName: client?.name || client?.companyName,
      deadline: (
        <div {...props}>{new Date(lead?.assemblyDeadline).toLocaleDateString()}</div>
      ),
      amount: lead?.doorCount ? parseInt(lead?.doorCount) : 0,
      aluminiumColor,
      date: new Date(lead?.createdAt).toLocaleDateString(),
      actions: getActions(lead),
      accessories: getAccessories(lead),
    });
  }

  console.log("dataSource:", dataSource); // Отладочный вывод

  return dataSource;
};


export const orderStatusColumnsActive = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 500,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 120,
  },
  {
    key: "deadline",
    title: "Срок",
    dataIndex: "deadline",
    sorter: (a, b) =>
      formatStringToDate(
        new Date(a.data.deadline).toLocaleDateString()
      ).getTime() -
      formatStringToDate(
        new Date(b.data.deadline).toLocaleDateString()
      ).getTime(),
    width: 100,
  },
  {
    key: "amount",
    title: (
      <div
        style={{
          width: 56,
        }}
      >
        Кол-во
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 65,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "accessories",
    title: "Фурнитура",
    dataIndex: "accessories",
    width: 200,
  },
  {
    key: "actions",
    title: "Завершить",
    dataIndex: "actions",
    width: 100,
  },
];

export const orderStatusColumnsReadyForDeparture = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 85,
  },
  {
    key: "fullName",
    title: (
      <div
        style={{
          width: 500,
        }}
      >
        ФИО
      </div>
    ),
    dataIndex: "fullName",
    width: 120,
  },
  {
    key: "amount",
    title: (
      <div
        style={{
          width: 56,
        }}
      >
        Кол-во
      </div>
    ),
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 65,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "montage",
    title: "Монтаж",
    dataIndex: "montage",
    width: 75,
  },
  {
    key: "actions",
    title: "Самовывоз",
    dataIndex: "actions",
    width: 100,
  },
];

export const orderStatusColumnsBooking = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 95,
  },
  {
    key: "agreementNumber",
    title: <div>№ дог.</div>,
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
    width: 200,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
  {
    key: "amount",
    title: <div>Кол-во</div>,
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 85,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 125,
  },
  {
    key: "accessories",
    title: "Фурнитура",
    dataIndex: "accessories",
    width: 200,
  },
];

export const orderStatusColumnsCompleted = [
  {
    key: "date",
    title: "Дата",
    dataIndex: "date",
    sorter: (a, b) =>
      formatStringToDate(a.date).getTime() -
      formatStringToDate(b.date).getTime(),
    width: 100,
  },
  {
    key: "agreementNumber",
    title: "№ дог.",
    dataIndex: "agreementNumber",
    sorter: (a, b) => a.agreementNumber - b.agreementNumber,
    width: 80,
  },
  {
    key: "fullName",
    title: "ФИО",
    dataIndex: "fullName",
    width: 250,
  },
  {
    key: "phoneNumber",
    title: "Телефон",
    dataIndex: "phoneNumber",
    width: 120,
  },
  {
    key: "address",
    title: "Адрес",
    dataIndex: "address",
  },
  {
    key: "amount",
    title: "Кол-во",
    dataIndex: "amount",
    sorter: (a, b) => a.amount - b.amount,
    width: 87,
  },
  {
    key: "responsibleUser",
    title: "Ответственный",
    dataIndex: "responsibleUser",
    width: 130,
  },
  {
    key: "aluminiumColor",
    dataIndex: "aluminiumColor",
    title: "Цвет",
    width: 100,
  },
];

import { message } from "antd";
import config from "../../../config.json";
import { patchRequest, postRequest } from "../../../utils/requests";

export const fetchStatuses = (
  state = "NEW",
  status = "",
  query = "",
  setSpecifications = () => {},
  setIsLoading = () => {}
) => {
  let url = `${config.service}/api/appl/state?search=${query}`;

  const body = {
    state,
  };
  if (status) {
    body.status = status;
  }

  const response = postRequest(url, body, {});

  response
    .then((data) => {
      const filteredSpecs = data.data.application.filter(spec => spec.isHasAssembly === true)
      // const filteredSpecs = data.data.application
      setSpecifications(filteredSpecs);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const acceptAccessories = (id, onFinish) => {
  const url = `${config.service}/api/accessories/accept-accessories/${id}`;

  const body = {
    status: "ACCEPTED",
  };

  const response = postRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const accept = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "LOOP_MILLING",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const completeAssembly = (id, isHasDelivery, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "READY_FOR_SHIPMENT",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

// export const confirmPickup = (id, onFinish) => {
//   const url = `${config.service}/api/appl/changeState/${id}`;
//   const body = {
//     state: "COMPLETED",
//     status: "ORDER_DELIVERED",
//   };

//   const response = patchRequest(url, body);

//   const hideLoadingMsg = message.loading("Загрузка...", 0);
//   response
//     .then(() => {
//       message.success("Готово", 3);
//     })
//     .catch(() => {
//       message.error("Ошибка", 3);
//     })
//     .finally(() => {
//       hideLoadingMsg();

//       if (onFinish) {
//         onFinish();
//       }
//     });
// };

export const returnToAssembly = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "ACTIVE",
    status: "LOOP_MILLING",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const refuseApplication = (id, onFinish) => {
  const url = `${config.service}/api/appl/changeState/${id}`;
  const body = {
    state: "NEW",
    status: "REFUSED",
  };

  const response = patchRequest(url, body);

  const hideLoadingMsg = message.loading("Загрузка...", 0);
  response
    .then(() => {
      message.success("Готово", 3);
    })
    .catch(() => {
      message.error("Ошибка", 3);
    })
    .finally(() => {
      hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

// export const updateApplication = (id, body, onFinish) => {
//   const url = `${config.service}/api/appl/changeStatus/${id}`;

//   const response = patchRequest(url, body);

//   const hideLoadingMsg = message.loading("Загрузка...", 0);
//   response
//     .then(() => {
//       message.success("Обновлено", 3);
//     })
//     .catch(() => {
//       message.error("Ошибка", 3);
//     })
//     .finally(() => {
//       hideLoadingMsg();

//       if (onFinish) {
//         onFinish();
//       }
//     });
// };

// export const updateApplicationStatus = (id, body, onFinish) => {
//   const url = `${config.service}/api/appl/changeStatus/${id}`;

//   const response = patchRequest(url, body);

//   const hideLoadingMsg = message.loading("Загрузка...", 0);
//   response
//     .then(() => {
//       message.success("Обновлено", 3);

//       if (onFinish) {
//         onFinish();
//       }
//     })
//     .catch(() => {
//       message.error("Ошибка", 3);
//     })
//     .finally(() => {
//       hideLoadingMsg();
//     });
// };
export const forceCompleteAssembly = (id) => {
  const url = `${config.service}/api/appl/force-complete-assembly/${id}`;
  return postRequest(url, {})
    .then((data) => {
      if (data?.status === 200) {
        message.success("Сборка принудительно завершена.", 3);
        return data?.data || '';
      }
      throw new Error(data?.data?.message || "Неизвестная ошибка");
    })
    .catch((error) => {
      let msg = "Неизвестная ошибка";
      if (error?.response?.data?.message) {
        msg = error.response.data.message;
      }
      message.error(msg);
      throw error;
    });
};

export const sendPickupCodeToClient = (id, phone, onSuccess, onFinish) => {
  const url = `${config.service}/api/appl/verificationCode/${id}`;
  const body = {
    phone: phone,
    type: "PICKUP",
  };
  // const hideLoadingMsg = message.loading("Отправляем код на WhatsApp...", 0);

  postRequest(url, body)
    .then(data => {
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      message.error("Ошибка", e);
    })
    .finally(() => {
      // hideLoadingMsg();

      if (onFinish) {
        onFinish();
      }
    });
};

export const confirmPickup = (pin, lead, onSuccess, onError, onFinish) => {
  const url = `${config.service}/api/appl/verifyDeliveryCode/${lead._id}`;
  const body = {
    id: lead._id,
    code: pin,
    type: "PICKUP",
    isHasMontage: lead.isHasMontage,
    phone: lead.client[0].phone,
  };

  postRequest(url, body)
    .then(data => {
      if (data?.status === 200) {
        onSuccess(data?.data || '');
      }
    })
    .catch((e) => {
      console.log('error while completing pickup', e);
      onError();
      // message.error("Неверный код", 3);
    })
    .finally(() => {
      if (onFinish) {
        onFinish();
      }
    });
}

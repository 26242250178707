import { Modal, Tooltip, Button, message } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { 
  accept, 
  acceptAccessories, 
  completeAssembly, 
  returnToAssembly, 
  sendPickupCodeToClient, 
  confirmPickup, 
  forceCompleteAssembly 
} from "../utils/fetch";
import config from "../../../config.json";
import PinInput from "react-pin-input";

/**
 * Переиспользуемый компонент модального окна сборки.
 */
const AssemblyModal = ({
  visible,
  onSubmit,
  onCancel,
  okText,
  content,
  loading,
}) => {
  return (
    <Modal
      title="Внимание!"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
      okText={okText}
      cancelText="Закрыть"
      okButtonProps={{
        style: {
          backgroundColor: okText === "Да, завершить" ? "red" : undefined,
          borderColor: okText === "Да, завершить" ? "red" : undefined,
        },
        loading: loading, // Показывает состояние загрузки, если loading=true
      }}
    >
      {content}
    </Modal>
  );
};

/**
 * Компонент кнопки для принятия фурнитуры.
 */
export const AcceptAccessoriesButton = ({ lead, fetch }) => {
  const [showAcceptAccessoriesModal, setShowAcceptAccessoriesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const content = (
    <>
      <p>Убедитесь, что фурнитура в верном объеме и нужного качества.</p>
      <p>Вы точно хотите принять фурнитуру?</p>
    </>
  );

  const handleAcceptAccessories = async () => {
    setIsLoading(true);
    try {
      await acceptAccessories(lead._id, fetch);
      message.success("Фурнитура успешно принята для сборки.", 3);
      setShowAcceptAccessoriesModal(false);
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        style={{
          padding: "5px 10px",
          color: "green",
          marginRight: 10,
        }}
        onClick={() => {
          setShowAcceptAccessoriesModal(true);
        }}
      >
        Принять фурнитуру
      </Button>
      <AssemblyModal 
        visible={showAcceptAccessoriesModal}
        onSubmit={handleAcceptAccessories}
        onCancel={() => setShowAcceptAccessoriesModal(false)}
        okText="Принять фурнитуру"
        content={content}
        loading={isLoading}
      />
    </>
  );
};

/**
 * Компонент кнопки для принятия сборки.
 */
export const AcceptButton = ({ lead, fetch }) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const content = (
    <>
      <p>Вы точно хотите начать сборку?</p>
    </>
  );

  const handleAccept = async () => {
    setIsLoading(true);
    try {
      await accept(lead._id, fetch);
      message.success("Сборка успешно начата.", 3);
      setShowAcceptModal(false);
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Начать сборку">
        <Button
          style={{
            padding: "5px 10px",
            color: "green",
            marginRight: 10,
          }}
          onClick={() => {
            setShowAcceptModal(true);
          }}
          loading={isLoading}
        >
          <CheckOutlined />
        </Button>
      </Tooltip>
      <AssemblyModal 
        visible={showAcceptModal}
        onSubmit={handleAccept}
        onCancel={() => setShowAcceptModal(false)}
        okText="Подтвердить"
        content={content}
        loading={isLoading}
      />
    </>
  );
};


export const CompleteAssemblyButton = ({ lead, fetch }) => {
  const [showCompleteAccessoriesModal, setShowCompleteAccessoriesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openDocument = (type = "agreement", id) => {
    window.open(`${config.service}/api/file/${type}/${id}`);
  };

  const onClick = () => {
    if (!lead.accessoriesAcceptedDate) {
      return message.error('Фурнитура не принята. Нажмите "Принять фурнитуру"', 5);
    }

    setShowCompleteAccessoriesModal(true);
  };

  const handleCompleteAssembly = async () => {
    setIsLoading(true);
    try {
      await completeAssembly(lead._id, lead.isHasDelivery, fetch);
      message.success("Сборка успешно завершена.", 3);
      setShowCompleteAccessoriesModal(false);
      // Если нужно открыть документ, раскомментируйте следующую строку
      // openDocument("delivery-certificate", lead._id);
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  const content = (
    <>
      <p>Вы точно хотите завершить сборку?</p>
    </>
  );

  return (
    <>
      <Tooltip title="Завершить сборку">
        <Button
          style={{
            padding: "10px 20px",
            color: "white",
            backgroundColor: "green", // Изменено для лучшей видимости
            marginRight: 10,
            border: "none",
            borderRadius: "4px",
          }}
          loading={isLoading}
          onClick={onClick}
        >
          Завершить сборку
        </Button>
      </Tooltip>
      <AssemblyModal 
        visible={showCompleteAccessoriesModal}
        onSubmit={handleCompleteAssembly}
        onCancel={() => setShowCompleteAccessoriesModal(false)}
        okText="Подтвердить"
        content={content}
        loading={isLoading}
      />
    </>
  );
};


/**
 * Компонент кнопки для подтверждения самовывоза.
 */
export const ConfirmPickupButton = ({ lead, fetch }) => {
  const [showConfirmPickupModal, setShowConfirmPickupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pin, setPin] = useState('');

  const onClick = async (phone, id) => {
    setIsLoading(true);
    try {
      await sendPickupCodeToClient(id, phone, (msg) => {
        if (msg) {
          message.success(msg, 3);
        }
        setShowConfirmPickupModal(true);
      });
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await confirmPickup(pin, lead, (msg) => {
        if (msg) {
          message.success(msg, 5);
        }
        setShowConfirmPickupModal(false);
      }, () => {
        message.error("Неверный код", 3);
      }, () => {
        fetch();
      });
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  const content = (
    <>
      <p>Введите код, который клиент получил на WhatsApp</p>
      <div className="my-3">
        <PinInput
          length={4}
          initialValue=""
          onChange={(value, _index) => {
            setPin(value);
          }}
          type="numeric"
          inputMode="number"
          inputStyle={{ borderColor: "#dee2e6" }}
          inputFocusStyle={{ borderColor: "#1890ff" }}
          autoSelect={true}
          regexCriteria={/^[0-9]*$/} // Принимает только числа
        />
      </div>
    </>
  );

  return (
    <>
      <Tooltip title="Выдать заказ">
        <Button
          style={{
            padding: "5px 10px",
            color: "green",
            marginRight: 10,
          }}
          loading={isLoading}
          onClick={() => {
            onClick(lead.client[0].phone, lead._id);
          }}
        >
          <CheckOutlined/>
        </Button>
      </Tooltip>
      <AssemblyModal
        visible={showConfirmPickupModal}
        onSubmit={onSubmit}
        onCancel={() => setShowConfirmPickupModal(false)}
        okText="Отправить"
        content={content}
        loading={isLoading}
      />
    </>
  );
};

/**
 * Компонент кнопки для возврата заказа на сборку.
 */
export const ReturnToAssemblyButton = ({ lead, fetch }) => {
  const [showReturnToAssemblyModal, setShowReturnToAssemblyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const content = (
    <>
      <p>Вы точно хотите вернуть заказ на сборку?</p>
    </>
  );

  const handleReturnToAssembly = async () => {
    setIsLoading(true);
    try {
      await returnToAssembly(lead._id, fetch);
      message.success("Заказ успешно возвращён на сборку.", 3);
      setShowReturnToAssemblyModal(false);
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Вернуть на сборку">
        <Button
          style={{
            padding: "5px 10px",
            color: "red",
            marginRight: 10,
          }}
          onClick={() => {
            setShowReturnToAssemblyModal(true);
          }}
        >
          <CloseOutlined />
        </Button>
      </Tooltip>
      <AssemblyModal 
        visible={showReturnToAssemblyModal}
        onSubmit={handleReturnToAssembly}
        onCancel={() => setShowReturnToAssemblyModal(false)}
        okText="Подтвердить"
        content={content}
        loading={isLoading}
      />
    </>
  );
};

/**
 * Компонент кнопки для принудительного завершения сборки.
 */
/**
 * Компонент кнопки для принудительного завершения сборки.
 */
export const ForceCompleteAssemblyButton = ({ lead, fetch }) => {
  console.log("ForceCompleteAssemblyButton рендерится для lead:", lead._id); // Отладочный вывод
  
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const content = (
    <>
      <p>Вы уверены, что хотите принудительно завершить сборку?</p>
    </>
  );

  const handleForceComplete = async () => {
    setIsLoading(true);
    try {
      await forceCompleteAssembly(lead._id);
      message.success("Сборка принудительно завершена.", 3);
      setShowForceCompleteModal(false);
      fetch();
    } catch (err) {
      let msg = "Неизвестная ошибка";
      if (err?.response?.data?.message) {
        msg = err.response.data.message;
      }
      message.error(msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Принудительно завершить сборку">
        <Button
          style={{
            padding: "10px 20px",
            color: "white",
            backgroundColor: "red", // Яркий фон для видимости
            marginRight: 10,
            border: "none", // Удаление границы
            borderRadius: "4px", // Скругление
          }}
          loading={isLoading}
          onClick={() => {
            setShowForceCompleteModal(true);
          }}
        >
          Принудительно завершить
        </Button>
      </Tooltip>
      <AssemblyModal
        visible={showForceCompleteModal}
        onSubmit={handleForceComplete}
        onCancel={() => setShowForceCompleteModal(false)}
        okText="Да, завершить"
        content={content}
        loading={isLoading}
      />
    </>
  );
};

